// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-casino-777-bay-js": () => import("./../../../src/pages/casino/777bay.js" /* webpackChunkName: "component---src-pages-casino-777-bay-js" */),
  "component---src-pages-casino-777-slotsbay-js": () => import("./../../../src/pages/casino/777slotsbay.js" /* webpackChunkName: "component---src-pages-casino-777-slotsbay-js" */),
  "component---src-pages-casino-auslots-js": () => import("./../../../src/pages/casino/auslots.js" /* webpackChunkName: "component---src-pages-casino-auslots-js" */),
  "component---src-pages-casino-betbtc-js": () => import("./../../../src/pages/casino/betbtc.js" /* webpackChunkName: "component---src-pages-casino-betbtc-js" */),
  "component---src-pages-casino-emu-js": () => import("./../../../src/pages/casino/emu.js" /* webpackChunkName: "component---src-pages-casino-emu-js" */),
  "component---src-pages-casino-js": () => import("./../../../src/pages/casino.js" /* webpackChunkName: "component---src-pages-casino-js" */),
  "component---src-pages-casino-luckystar-js": () => import("./../../../src/pages/casino/luckystar.js" /* webpackChunkName: "component---src-pages-casino-luckystar-js" */),
  "component---src-pages-casino-spacelilly-js": () => import("./../../../src/pages/casino/spacelilly.js" /* webpackChunkName: "component---src-pages-casino-spacelilly-js" */),
  "component---src-pages-de-casino-777-bay-js": () => import("./../../../src/pages/de/casino/777bay.js" /* webpackChunkName: "component---src-pages-de-casino-777-bay-js" */),
  "component---src-pages-de-casino-777-slotsbay-js": () => import("./../../../src/pages/de/casino/777slotsbay.js" /* webpackChunkName: "component---src-pages-de-casino-777-slotsbay-js" */),
  "component---src-pages-de-casino-auslots-js": () => import("./../../../src/pages/de/casino/auslots.js" /* webpackChunkName: "component---src-pages-de-casino-auslots-js" */),
  "component---src-pages-de-casino-betbtc-js": () => import("./../../../src/pages/de/casino/betbtc.js" /* webpackChunkName: "component---src-pages-de-casino-betbtc-js" */),
  "component---src-pages-de-casino-emu-js": () => import("./../../../src/pages/de/casino/emu.js" /* webpackChunkName: "component---src-pages-de-casino-emu-js" */),
  "component---src-pages-de-casino-js": () => import("./../../../src/pages/de/casino.js" /* webpackChunkName: "component---src-pages-de-casino-js" */),
  "component---src-pages-de-casino-luckystar-js": () => import("./../../../src/pages/de/casino/luckystar.js" /* webpackChunkName: "component---src-pages-de-casino-luckystar-js" */),
  "component---src-pages-de-casino-spacelilly-js": () => import("./../../../src/pages/de/casino/spacelilly.js" /* webpackChunkName: "component---src-pages-de-casino-spacelilly-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-myneosurf-js": () => import("./../../../src/pages/de/myneosurf.js" /* webpackChunkName: "component---src-pages-de-myneosurf-js" */),
  "component---src-pages-de-paysafecard-js": () => import("./../../../src/pages/de/paysafecard.js" /* webpackChunkName: "component---src-pages-de-paysafecard-js" */),
  "component---src-pages-fr-casino-777-bay-js": () => import("./../../../src/pages/fr/casino/777bay.js" /* webpackChunkName: "component---src-pages-fr-casino-777-bay-js" */),
  "component---src-pages-fr-casino-777-slotsbay-js": () => import("./../../../src/pages/fr/casino/777slotsbay.js" /* webpackChunkName: "component---src-pages-fr-casino-777-slotsbay-js" */),
  "component---src-pages-fr-casino-auslots-js": () => import("./../../../src/pages/fr/casino/auslots.js" /* webpackChunkName: "component---src-pages-fr-casino-auslots-js" */),
  "component---src-pages-fr-casino-betbtc-js": () => import("./../../../src/pages/fr/casino/betbtc.js" /* webpackChunkName: "component---src-pages-fr-casino-betbtc-js" */),
  "component---src-pages-fr-casino-emu-js": () => import("./../../../src/pages/fr/casino/emu.js" /* webpackChunkName: "component---src-pages-fr-casino-emu-js" */),
  "component---src-pages-fr-casino-js": () => import("./../../../src/pages/fr/casino.js" /* webpackChunkName: "component---src-pages-fr-casino-js" */),
  "component---src-pages-fr-casino-luckystar-js": () => import("./../../../src/pages/fr/casino/luckystar.js" /* webpackChunkName: "component---src-pages-fr-casino-luckystar-js" */),
  "component---src-pages-fr-casino-spacelilly-js": () => import("./../../../src/pages/fr/casino/spacelilly.js" /* webpackChunkName: "component---src-pages-fr-casino-spacelilly-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-myneosurf-js": () => import("./../../../src/pages/fr/myneosurf.js" /* webpackChunkName: "component---src-pages-fr-myneosurf-js" */),
  "component---src-pages-fr-paysafecard-js": () => import("./../../../src/pages/fr/paysafecard.js" /* webpackChunkName: "component---src-pages-fr-paysafecard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-myneosurf-js": () => import("./../../../src/pages/myneosurf.js" /* webpackChunkName: "component---src-pages-myneosurf-js" */),
  "component---src-pages-paysafecard-js": () => import("./../../../src/pages/paysafecard.js" /* webpackChunkName: "component---src-pages-paysafecard-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/BlogList.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

